import { RecursiveCSSObject, SystemCSSProperties } from "@chakra-ui/react";

import responsiveStyle from "@lib/theme/responsiveStyle";

import { DEFAULT_DESKTOP_FONT_SIZE, DEFAULT_FONT_COLOR, DEFAULT_MOBILE_FONT_SIZE } from "../constants";
import type { TMessageData } from "../types";

const getTextStyles = ({
  fontSizeDesktop,
  fontSizeMobile,
  fontColorDesktop,
  fontColorMobile,
  animationDuration,
}: TMessageData): Record<string, RecursiveCSSObject<SystemCSSProperties>> => ({
  fontSize: responsiveStyle({
    mobile: `${fontSizeMobile || DEFAULT_MOBILE_FONT_SIZE}px`,
    desktop: `${fontSizeDesktop || DEFAULT_DESKTOP_FONT_SIZE}px`,
  }),
  color: responsiveStyle({
    mobile: fontColorMobile || DEFAULT_FONT_COLOR,
    desktop: fontColorDesktop || DEFAULT_FONT_COLOR,
  }),
  textAlign: responsiveStyle({
    mobile: animationDuration ? "left" : "center",
    desktop: "left",
  }),
});
export default getTextStyles;
