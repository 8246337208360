import { FC } from "react";

import { useAuth } from "@modules/auth";

import LoggedInActions from "./LoggedInActions";
import LoggedOutActions from "./LoggedOutActions";

const LandingActions: FC = () => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? <LoggedInActions key="logged-in-actions" /> : <LoggedOutActions key="logged-out-actions" />;
};
export default LandingActions;
