import { INotifications } from "../models/notifications";
import type { TMessageData } from "../types";

const mapNotificationsToMessage = (notifications: INotifications | null): TMessageData | null => {
  if (!notifications?.items?.length) {
    return null;
  }

  const { sliderSpeed: animationDuration } = notifications;

  const firstItem = notifications.items[0];
  const { backgroundColor, link } = firstItem;

  if (!firstItem.texts?.length) {
    return null;
  }

  const firstText = firstItem.texts[0];
  const { text, fontSizeMobile, fontSizeDesktop, fontColorMobile, fontColorDesktop } = firstText;

  return {
    text,
    link: link || undefined,
    animationDuration,
    backgroundColor: backgroundColor || undefined,
    fontSizeMobile: fontSizeMobile || undefined,
    fontSizeDesktop: fontSizeDesktop || undefined,
    fontColorMobile: fontColorMobile || undefined,
    fontColorDesktop: fontColorDesktop || undefined,
  };
};
export default mapNotificationsToMessage;
