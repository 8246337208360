import { FC } from "react";

import { Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import responsiveStyle from "@lib/theme/responsiveStyle";

import ActionCard from "./components/ActionCard";

const LoggedOutActions: FC = () => {
  const { t } = useTranslation();
  const orderCardHeight = responsiveStyle({ mobile: "3.4375rem", desktop: "9.375rem" });
  const cardHeight = responsiveStyle({ mobile: "2.8125rem", desktop: "9.375rem" });
  const cardFlex = responsiveStyle({ mobile: "auto", desktop: "1" });
  return (
    <Stack
      direction={responsiveStyle({ mobile: "column", desktop: "row" })}
      spacing={responsiveStyle({ mobile: "1.25rem", desktop: "1rem" })}
      px={responsiveStyle({ mobile: "1.5rem", desktop: "0" })}
    >
      <ActionCard
        href="/order"
        label={t("landing_action-send_parcel")}
        subText={t("landing_action-send_package-subtext")}
        variant="primary"
        height={orderCardHeight}
        flex={cardFlex}
        fontSize={responsiveStyle({ mobile: "1.125rem", desktop: "2rem" })}
        id="signed-out-order-action"
        hasMultiLines
      />
      <ActionCard
        href={t("landing_action-track_your_parcel-href")}
        isExternal
        label={t("landing_action-track_your_parcel")}
        variant="dark"
        height={cardHeight}
        flex={cardFlex}
        maxLabelWidth={responsiveStyle({ mobile: "100%", desktop: "90%" })}
        id="signed-out-track-action"
        hasMultiLines
      />
      <ActionCard
        href={t("landing_action-machine_locations-href")}
        isExternal
        label={t("landing_action-machine_locations")}
        variant="dark"
        height={cardHeight}
        flex={cardFlex}
        id="signed-out-machine-locations-action"
        hasMultiLines
      />
    </Stack>
  );
};
export default LoggedOutActions;
