import { FC } from "react";

import { VStack, HStack, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import Icon from "@components/Icon";
import responsiveStyle from "@lib/theme/responsiveStyle";
import { ROUTES } from "@utils/constants";

import ActionCard from "./components/ActionCard";

const LoggedInActions: FC = () => {
  const { t } = useTranslation();
  return (
    <VStack spacing={responsiveStyle({ mobile: "0.75rem", desktop: "1.25rem" })}>
      <Stack
        direction={responsiveStyle({ mobile: "column", desktop: "row" })}
        spacing={responsiveStyle({ mobile: "2rem", desktop: "1.25rem" })}
        width="full"
      >
        <ActionCard
          href={ROUTES.ORDER}
          label={t("landing_action-send_parcel")}
          variant="primary"
          height={responsiveStyle({ mobile: "3.4375rem", desktop: "9.375rem" })}
          flex={responsiveStyle({ mobile: "auto", desktop: "1" })}
          fontSize={responsiveStyle({ mobile: "1.125rem", desktop: "2rem" })}
          id="signed-in-order-action"
        />
        <ActionCard
          href={ROUTES.SHIPMENTS}
          label={t("landing_action-shipments")}
          icon={<Icon icon="delivery" size={responsiveStyle({ mobile: "1.875rem", desktop: "3rem" })} />}
          variant="secondary"
          height={responsiveStyle({ mobile: "2.8125rem", desktop: "9.375rem" })}
          flex={responsiveStyle({ mobile: "auto", desktop: "1" })}
          id="signed-in-shipments-action"
        />
      </Stack>
      <HStack spacing={responsiveStyle({ mobile: "0.75rem", desktop: "1.25rem" })} width="full">
        <ActionCard
          href={ROUTES.PROFILE_MY_ADDRESSES}
          label={t("landing_action-your_profile")}
          variant="secondary"
          icon={<Icon icon="profileThin" size={responsiveStyle({ mobile: "1.875rem", desktop: "2.5rem" })} />}
          height={responsiveStyle({ mobile: "7.5rem", desktop: "9.375rem" })}
          flex="1"
          isMobileVertical
          id="signed-in-profile-action"
        />
        <ActionCard
          href={ROUTES.USER_DATA_ADDRESS_BOOK}
          label={t("landing_action-address_book")}
          variant="secondary"
          icon={<Icon icon="email" size={responsiveStyle({ mobile: "1.875rem", desktop: "2.5rem" })} />}
          height={responsiveStyle({ mobile: "7.5rem", desktop: "9.375rem" })}
          flex="1"
          isMobileVertical
          id="signed-in-address-book-action"
        />
        <ActionCard
          href={ROUTES.USER_DATA_INVOICES}
          label={t("landing_action-invoices")}
          variant="secondary"
          icon={<Icon icon="export" size={responsiveStyle({ mobile: "1.5rem", desktop: "2rem" })} />}
          height={responsiveStyle({ mobile: "7.5rem", desktop: "9.375rem" })}
          flex="1"
          isMobileVertical
          id="signed-in-invoices-action"
        />
      </HStack>
    </VStack>
  );
};
export default LoggedInActions;
