import { FC } from "react";

import { Box } from "@chakra-ui/react";
import { GetServerSideProps, InferGetServerSidePropsType as Infer } from "next";
import { useTranslation, i18n } from "next-i18next";

import { FullWidthVStack } from "@components/common";
import { ContextMessage } from "@components/Message";
import LayoutHead from "@components/PageHeader/LayoutHead";
import { Mobile } from "@lib/media";
import responsiveStyle from "@lib/theme/responsiveStyle";
import withPropsMiddleware from "@lib/withPropsMiddleware";
import { useAuth } from "@modules/auth";
import { BannersLanding, loadBanners } from "@modules/banners";
import { FaqList, loadFaq, TFaq } from "@modules/faq";
import { loadFeatures } from "@modules/features";
import LandingActions from "@modules/LandingActions";
import Layout, { TWithLayout, LayoutContent } from "@modules/layout";
import Notifications, { INotifications, loadNotifications } from "@modules/notifications";
import getEnv from "@utils/getEnv";
import { IBanners } from "@modules/banners/models/banners";
import { TListWithPagination } from "@lib/pagination";

type TWithLocales = {
  defaultLocale: string;
  customLocales: string[];
  customLocale: string; // Change from string? to string
  features: string[] | null;
  banners: IBanners | null;
  faq: TListWithPagination<TFaq> | null;
  notifications: INotifications | null;
};

export const getServerSideProps: GetServerSideProps<TWithLocales> = withPropsMiddleware(async (context) => {
  const {
    locale,
    req: {
      headers: { host },
    },
  } = context;

  const notificationsZone = getEnv("NEXT_PUBLIC_NOTIFICATION_LANDING_ZONE") as string;
  const notificationsPromise = locale ? loadNotifications(notificationsZone, locale, host!) : null;

  const bannersZone = getEnv("NEXT_PUBLIC_BANNERS_LANDING_ZONE") as string;
  const bannersPromise = locale ? loadBanners(bannersZone, locale, host!) : null;

  const faqPromise = locale ? loadFaq(true, locale, host!) : null;

  const [notifications, banners, faq] = await Promise.all([notificationsPromise, bannersPromise, faqPromise]);

  const features = locale ? await loadFeatures(locale, host) : null;

  return {
    props: { notifications, banners, faq, features, defaultLocale: "", customLocales: [], customLocale: "" },
  };
});

const MOBILE_TITLE_TRANSLATION_KEY = "landing_mobile-title";

const LandingPage: TWithLayout<FC<Infer<typeof getServerSideProps>>> = ({ banners, notifications, faq }) => {
  const { t } = useTranslation();

  const { isLoggedIn } = useAuth();

  return (
    <>
      <LayoutHead title={t("pages-landing-title")} description={t("pages-landing-description")} />
      <LayoutContent>
        <FullWidthVStack spacing="1.5rem" mb="1.5rem">
          <BannersLanding bannersData={banners} />
          <FullWidthVStack spacing="1.5rem" pb={responsiveStyle({ mobile: isLoggedIn ? "0" : "2rem", desktop: "0" })}>
            <Notifications notificationsData={notifications} />
            <ContextMessage messageZone="landing" />
          </FullWidthVStack>
          {!isLoggedIn && i18n?.exists(MOBILE_TITLE_TRANSLATION_KEY) && (
            <Mobile>
              <Box textAlign="center" fontSize="1.875rem" fontWeight="light">
                {t(MOBILE_TITLE_TRANSLATION_KEY)}
              </Box>
            </Mobile>
          )}
          <LandingActions />
          <FaqList faq={faq?.items || null} />
        </FullWidthVStack>
      </LayoutContent>
    </>
  );
};

LandingPage.Layout = <Layout hasMobileWebchat />;

export default LandingPage;
