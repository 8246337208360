import { FC } from "react";

import { useMultiStyleConfig, chakra, Box, Flex } from "@chakra-ui/react";

import { LinkWrapper } from "@components/Link";
import { Desktop } from "@lib/media";
import responsiveStyle from "@lib/theme/responsiveStyle";

import type { TActionCardProps } from "./types";

const ActionCard: FC<TActionCardProps> = ({
  href,
  isExternal,
  variant,
  label,
  subText,
  icon,
  height,
  flex,
  maxLabelWidth,
  isMobileVertical,
  fontSize,
  id,
  hasMultiLines,
}) => {
  const styles = useMultiStyleConfig("ActionCard", { variant });

  return (
    <LinkWrapper href={href} isExternal={isExternal}>
      <chakra.a
        sx={{
          ...styles.card,
          ...(!!icon && styles.cardWithIcon),
          ...(!!isMobileVertical && styles.cardVertical),
          ...(!!fontSize && { fontSize }),
        }}
        height={height}
        flex={flex}
        id={id}
      >
        {icon && <Box sx={{ ...styles.iconContainer, ...(!!isMobileVertical && styles.iconVertical) }}>{icon}</Box>}
        <Flex
          align="flex-end"
          justify={responsiveStyle({ mobile: "center", desktop: "flex-start" })}
          flex={!icon ? "1" : "initial"}
        >
          <Box sx={{ ...styles.label, ...(hasMultiLines && styles.multilineLabel) }} maxWidth={maxLabelWidth}>
            {label}
          </Box>
          {subText && (
            <Desktop>
              {(className) => (
                <Box sx={styles.subText} className={className}>
                  {subText}
                </Box>
              )}
            </Desktop>
          )}
        </Flex>
      </chakra.a>
    </LinkWrapper>
  );
};
export default ActionCard;
