import { FC } from "react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, chakra } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import { LinkWrapper } from "@components/Link";
import Message from "@components/Message";
import responsiveStyle from "@lib/theme/responsiveStyle";

import type { TNotificationsProps } from "./types";
import getTextStyles from "./utils/getTextStyles";
import mapNotificationsToMessage from "./utils/mapNotificationsToMessage";

const Notifications: FC<TNotificationsProps> = ({ notificationsData }) => {
  const { t } = useTranslation();

  const messageData = mapNotificationsToMessage(notificationsData);

  if (!messageData) {
    return null;
  }

  const { text, animationDuration, backgroundColor, link } = messageData;

  const textStyles = getTextStyles(messageData);

  return (
    <LinkWrapper href={link} isExternal key="notification-link">
      <Box as={link ? "a" : "div"} _hover={{ "& span": { textDecoration: link ? "underline" : "none" } }}>
        <Message
          variant="danger"
          isAnimated={!!animationDuration}
          animationDuration={animationDuration}
          backgroundColor={backgroundColor}
          justify={responsiveStyle({ mobile: animationDuration ? "start" : "center", desktop: "start" })}
        >
          <Box sx={textStyles}>
            {link ? (
              <chakra.span sx={{ ...textStyles, marginLeft: "0.5rem" }}>
                {`${text} ${t("notifications-read_more")}`}
                <ChevronRightIcon width="1.5rem" height="1.5rem" marginLeft="0.1rem" />
              </chakra.span>
            ) : (
              text
            )}
          </Box>
        </Message>
      </Box>
    </LinkWrapper>
  );
};
export default Notifications;
